import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Services } from '../utils'
import Context from '../utils/Context'

export default class Logout extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: false,
    }

    this.logout = this.logout.bind(this)
  }

  logout({ account, scope, setGlobalLoading, parentAppId }) {
    const { onSuccess, onFailure } = this.props

    return Services.logout({ account, scope, parentAppId })
      .then(() => {
        this.setState({ loading: false }, () => {
          return setGlobalLoading(false, onSuccess)
        })
      })
      .catch(error => {
        this.setState({ loading: false }, () => {
          setGlobalLoading(false, () => onFailure(error))
        })
      })
  }

  render() {
    return (
      <Context.Consumer>
        {({
          state: { account, scope },
          handlers: { setGlobalLoading },
          parentAppId,
        }) =>
          this.props.children({
            loading: this.state.loading,
            action: () =>
              this.setState({ loading: true }, () =>
                setGlobalLoading(true, () =>
                  this.logout({ account, scope, setGlobalLoading, parentAppId })
                )
              ),
          })
        }
      </Context.Consumer>
    )
  }
}

Logout.propTypes = {
  children: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
  onFailure: PropTypes.func,
}

Logout.defaultProps = {
  redirect: false,
  onSuccess: () => {},
  onFailure: () => {},
}
