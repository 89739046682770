import React, { useEffect, useState, useContext, useCallback } from 'react'
import PropTypes from 'prop-types'

import { Services } from '../utils'
import Context from '../utils/Context'
import getError from '../utils/getError'

const SamlRedirect = ({
  children,
  provider,
  onSuccess = () => {},
  onFailure = () => {},
  autorun = false,
  useNewSession = false,
}) => {
  const [loading, setLoading] = useState(false)
  const {
    // eslint-disable-next-line camelcase
    handlers: { setGlobalLoading, DEPRECATED_withSession },
  } = useContext(Context)

  const startSessionAndRedirect = useCallback(() => {
    setLoading(true)
    setGlobalLoading(true)

    const onDone = () => {
      setLoading(false)
      setGlobalLoading(false)
    }

    const start = useNewSession
      ? DEPRECATED_withSession(() => Services.redirectSaml({ provider }))
      : Services.redirectSaml({ provider })

    start
      .then(() => {
        onSuccess()
        onDone()
      })
      .catch(error => {
        onFailure(getError(error))
        onDone()
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [provider])

  useEffect(() => {
    autorun && startSessionAndRedirect()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autorun])

  if (!children) return <></>
  if (typeof children === 'function') {
    return children({
      loading,
      action: startSessionAndRedirect,
    })
  }

  return children
}

SamlRedirect.proptypes = {
  children: PropTypes.oneOfType([PropTypes.func.isRequired, PropTypes.node])
    .isRequired,
  onSuccess: PropTypes.func,
  onFailure: PropTypes.func,
  autorun: PropTypes.bool,
  useNewSession: PropTypes.bool,
}

export default SamlRedirect
