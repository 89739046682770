import { useEffect, useContext, useCallback } from 'react'
import PropTypes from 'prop-types'

import { Services } from '../utils'
import Context from '../utils/Context'

const RedirectAfterLogin = ({ children, autorun }) => {
  const {
    state: { returnUrl },
  } = useContext(Context)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const redirect = useCallback(() => Services.redirect({ returnUrl }))

  useEffect(() => {
    autorun && redirect()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autorun])

  return children ? children({ action: redirect }) : null
}

RedirectAfterLogin.propTypes = {
  children: PropTypes.func,
  autorun: PropTypes.bool,
}

export default RedirectAfterLogin
