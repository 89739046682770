import React from 'react'
import PropTypes from 'prop-types'

import { Services } from '../utils'
import Context from '../utils/Context'

const RedirectLogout = ({ children, returnUrl }) => (
  <Context.Consumer>
    {({ state: { account, scope } }) =>
      children({
        action: () => Services.redirectLogout({ account, scope, returnUrl }),
      })
    }
  </Context.Consumer>
)

RedirectLogout.propTypes = {
  children: PropTypes.func.isRequired,
  returnUrl: PropTypes.string,
}

export default RedirectLogout
