import { useEffect, useState, useContext, useCallback } from 'react'
import PropTypes from 'prop-types'

import { Services } from '../utils'
import Context from '../utils/Context'
import getError from '../utils/getError'

const OAuthRedirect = ({
  children,
  provider,
  onSuccess = () => {},
  onFailure = () => {},
  autorun = false,
  useNewSession = false,
  errorFallbackUrl = null,
}) => {
  const [loading, setLoading] = useState(false)
  const {
    // eslint-disable-next-line camelcase
    handlers: { setGlobalLoading, DEPRECATED_withSession },
  } = useContext(Context)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const startSessionAndRedirect = useCallback(() => {
    setLoading(true)
    setGlobalLoading(true)

    const onDone = () => {
      setLoading(false)
      setGlobalLoading(false)
    }

    const start = useNewSession
      ? DEPRECATED_withSession(() =>
          Services.redirectOAuth({ provider, errorFallbackUrl })
        )
      : Services.redirectOAuth({ provider, errorFallbackUrl })

    start
      .then(() => {
        onSuccess()
        onDone()
      })
      .catch(error => {
        onFailure(getError(error))
        onDone()
      })
  })

  useEffect(() => {
    autorun && startSessionAndRedirect()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autorun])

  return children({
    loading,
    action: startSessionAndRedirect,
  })
}

OAuthRedirect.proptypes = {
  children: PropTypes.func.isRequired,
  provider: PropTypes.string.isRequired,
  onSuccess: PropTypes.func,
  onFailure: PropTypes.func,
  autorun: PropTypes.bool,
  useNewSession: PropTypes.bool,
}

export default OAuthRedirect
