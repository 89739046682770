import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Context from '../utils/Context'
import { Services, Validations, Constants } from '../utils'
import getError from '../utils/getError'

const { ApiAuthStatus: API_AUTH_STATUS } = Constants
// import ReCAPTCHA from './ReCAPTCHA'

export default class LoginWithAccessKey extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: false,
    }

    this.verifyToken = this.verifyToken.bind(this)
  }

  verifyToken({
    email,
    token,
    userAccounts,
    recaptcha,
    handlers: { setGlobalLoading, handleUserAccountsChange },
    parentAppId,
  }) {
    const { onSuccess, onFailure, saveUserAccount, disableSetPreference } =
      this.props

    return Services.validateToken({
      login: email,
      accesskey: token,
      recaptcha,
      recaptchaToken: null,
      setPreference: !disableSetPreference,
      parentAppId,
    })
      .then(({ authStatus }) => {
        this.setState({ loading: false }, () => {
          if (authStatus === API_AUTH_STATUS.Success) {
            if (saveUserAccount && !userAccounts.includes(email)) {
              handleUserAccountsChange([...userAccounts, email])
            }

            return setGlobalLoading(false, onSuccess)
          }

          return setGlobalLoading(false, () =>
            onFailure(getError({ authStatus }))
          )
        })
      })
      .catch(errorCode => {
        this.setState({ loading: false }, () => {
          setGlobalLoading(false, () => onFailure(getError(errorCode)))
        })
      })
  }

  render() {
    return (
      <Context.Consumer>
        {({
          state: { email, token, userAccounts, recaptcha },
          handlers,
          parentAppId,
        }) =>
          this.props.children({
            state: { email, token },
            loading: this.state.loading,
            action: () =>
              this.setState({ loading: true }, () =>
                handlers.setGlobalLoading(true, () => {
                  return this.verifyToken({
                    email,
                    token,
                    userAccounts,
                    recaptcha,
                    handlers,
                    parentAppId,
                  })
                })
              ),
            validation: Validations,
          })
        }
      </Context.Consumer>
    )
  }
}

LoginWithAccessKey.propTypes = {
  children: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
  onFailure: PropTypes.func,
  saveUserAccount: PropTypes.bool,
  disableSetPreference: PropTypes.bool,
}

LoginWithAccessKey.defaultProps = {
  onSuccess: () => {},
  onFailure: () => {},
  disableSetPreference: false,
}
