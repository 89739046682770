import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Services, Validations } from '../utils'
import Context from '../utils/Context'
import getError from '../utils/getError'

export default class ResendMfaToken extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: false,
      error: null,
    }

    this.resendMfaToken = this.resendMfaToken.bind(this)
  }

  resendMfaToken({ setGlobalLoading, parentAppId }) {
    const { onSuccess, onFailure } = this.props

    return Services.resendMfa({ parentAppId })
      .then(({ authStatus }) => {
        this.setState({ loading: false }, () => {
          if (authStatus === 'Pending') {
            setGlobalLoading(false, onSuccess)

            return
          }

          setGlobalLoading(false, () => onFailure({ authStatus }))
        })
      })
      .catch(error => {
        this.setState({ loading: false, error }, () => {
          setGlobalLoading(false, () => onFailure(getError(error)))
        })
      })
  }

  render() {
    return (
      <Context.Consumer>
        {({
          state: { phoneNumber },
          handlers: { setGlobalLoading },
          parentAppId,
        }) =>
          this.props.children({
            state: { phoneNumber },
            ...this.state,
            action: () =>
              this.setState({ loading: true }, () =>
                setGlobalLoading(true, () =>
                  this.resendMfaToken({
                    setGlobalLoading,
                    parentAppId,
                  })
                )
              ),
            validation: Validations,
          })
        }
      </Context.Consumer>
    )
  }
}

ResendMfaToken.propTypes = {
  children: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
  onFailure: PropTypes.func,
}

ResendMfaToken.defaultProps = {
  onSuccess: () => {},
  onFailure: () => {},
}
