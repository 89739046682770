import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Services, Validations, Constants } from '../utils'
import Context from '../utils/Context'
import getError from '../utils/getError'

const { ApiAuthStatus: API_AUTH_STATUS } = Constants

export default class RegisterMfaPhone extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: false,
      error: null,
    }

    this.registerMfaPhoneNumber = this.registerMfaPhoneNumber.bind(this)
  }

  registerMfaPhoneNumber({ phoneNumber, setGlobalLoading, parentAppId }) {
    const { useNewSession, onSuccess, onFailure } = this.props

    return (
      useNewSession
        ? Services.withMfaSession(
            () =>
              Services.registerMfaPhoneNumber({
                phoneNumber,
                parentAppId,
              }),
            { parentAppId }
          )
        : Services.registerMfaPhoneNumber({
            phoneNumber,
            parentAppId,
          })
    )
      .then(({ authStatus }) => {
        this.setState({ loading: false }, () => {
          if (authStatus === API_AUTH_STATUS.Pending) {
            setGlobalLoading(false, onSuccess)

            return
          }

          setGlobalLoading(false, () => onFailure({ authStatus }))
        })
      })
      .catch(error => {
        this.setState({ loading: false, error }, () => {
          setGlobalLoading(false, () => onFailure(getError(error)))
        })
      })
  }

  render() {
    return (
      <Context.Consumer>
        {({
          state: { phoneNumber },
          handlers: { setGlobalLoading },
          parentAppId,
        }) =>
          this.props.children({
            state: { phoneNumber },
            loading: this.state.loading,
            action: () => {
              this.setState({ loading: true }, () =>
                setGlobalLoading(true, () =>
                  this.registerMfaPhoneNumber({
                    phoneNumber,
                    setGlobalLoading,
                    parentAppId,
                  })
                )
              )
            },
            validation: Validations,
          })
        }
      </Context.Consumer>
    )
  }
}

RegisterMfaPhone.propTypes = {
  children: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
  onFailure: PropTypes.func,
  useNewSession: PropTypes.bool,
}

RegisterMfaPhone.defaultProps = {
  onSuccess: () => {},
  onFailure: () => {},
  useNewSession: false,
}
